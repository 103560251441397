import React from 'react'

const ARROW_LEFT = {
    d: 'M353.7 461.5l111.6-111.6c15-15 15-39.4 0-54.4s-39.4-15-54.4 0L233.6 472.8c-15 15-15 39.4 0 54.4l177.3 177.3c15 15 39.4 15 54.4 0s15-39.4 0-54.4L353.7 538.5h377.1c21.2 0 38.4-17.3 38.4-38.5s-17.2-38.5-38.4-38.5H353.7zM500 1000c276.1 0 500-223.9 500-500S776.1 0 500 0 0 223.9 0 500s223.9 500 500 500zm0-923.1c233.7 0 423.1 189.4 423.1 423.1 0 233.7-189.4 423.1-423.1 423.1-233.7 0-423.1-189.4-423.1-423.1C76.9 266.3 266.3 76.9 500 76.9z',
    viewBox: '0 0 1000 1000',
}

const ARROW_RIGHT = {
    d: 'M646.3 538.5L534.7 650.1c-15 15-15 39.4 0 54.4s39.4 15 54.4 0l177.3-177.3c15-15 15-39.4 0-54.4L589.1 295.5c-15-15-39.4-15-54.4 0s-15 39.4 0 54.4l111.6 111.6H269.2c-21.2 0-38.4 17.3-38.4 38.5s17.2 38.5 38.4 38.5h377.1zM500 0C223.9 0 0 223.9 0 500s223.9 500 500 500 500-223.9 500-500S776.1 0 500 0zm0 923.1C266.3 923.1 76.9 733.7 76.9 500 76.9 266.3 266.3 76.9 500 76.9c233.7 0 423.1 189.4 423.1 423.1 0 233.7-189.4 423.1-423.1 423.1z',
    viewBox: '0 0 1000 1000',
}


const BOOK = {
    d: 'M750 541.6H500c-11 0-21.6 4.4-29.4 12.2L458.4 566V142.2l58.9-58.9H750v458.3zm-416.6 0h-250V83.3h232.7l58.9 58.9V566l-12.2-12.2c-7.8-7.8-18.4-12.2-29.4-12.2zM791.7 0H500c-11 0-21.6 4.3-29.4 12.2l-53.9 53.9-53.9-53.9C355 4.3 344.4 0 333.4 0H41.7C18.7 0 0 18.6 0 41.6v541.7c0 23 18.7 41.7 41.7 41.7h274.4l71.1 71.1c8.2 8.1 18.8 12.2 29.5 12.2s21.3-4.1 29.5-12.2l71.1-71.1h274.4c23 0 41.7-18.7 41.7-41.7V41.6c0-23-18.7-41.6-41.7-41.6z',
    viewBox: '0 0 834 709',
}

const CLOSE = {
    d: 'M19.71 4.29c.36.36.385.927.077 1.316l-.077.086L13.4 12l6.309 6.308a.991.991 0 0 1-1.316 1.479l-.086-.077L12 13.4l-6.308 6.31a.991.991 0 0 1-1.479-1.316l.077-.086L10.597 12 4.29 5.692a.991.991 0 0 1 1.316-1.479l.086.077L12 10.597l6.308-6.307a.991.991 0 0 1 1.402 0z',
    viewBox: '0 0 24 24',
}

const GOLEM_EYE = {
    d: 'M160.4 494.9c83.4-100.8 321.8-353.5 617.4-353.5 294.5 0 533.7 252.9 617.3 353.6-83.3 100.8-321.8 353.5-617.3 353.5-294.5 0-533.8-252.9-617.4-353.6zM777.8 0C337.9 0 25.9 435.7 12.9 454.2c-17.2 24.5-17.2 57 0 81.5 13 18.5 324.9 454.2 764.9 454.2s751.8-435.7 764.8-454.2c17.3-24.5 17.3-57 0-81.5C1529.6 435.7 1217.7 0 777.8 0zM636.4 494.9c0-77.9 63.4-141.4 141.4-141.4S919.2 417 919.2 495s-63.4 141.4-141.4 141.4-141.4-63.5-141.4-141.5zm-141.5.1c0 155.9 126.9 282.8 282.9 282.8 156 0 282.8-126.9 282.8-282.9 0-155.9-126.8-282.8-282.8-282.8S494.9 339 494.9 495z',
    viewBox: '0 0 1556 990',
}

const GOLEM_RIGHT_ARROW = {
    d: 'M374.4 457.6L0 831.9l83.2 83.2 457.6-457.5L83.2 0 0 83.2',
    viewBox: '0 0 541 916',
}

const HIGH = {
    d: 'M900 0H100C44.8 0 0 44.8 0 100v800c0 55.2 44.8 100 100 100h800c55.2 0 100-44.8 100-100V100c0-55.2-44.8-100-100-100zm0 900H100V700h800v200zm0-300H100V400h800v200zm0-300H100V100h800v200zM650 850c-27.6 0-50-22.4-50-50s22.4-50 50-50 50 22.4 50 50-22.4 50-50 50zm0-300c-27.6 0-50-22.4-50-50s22.4-50 50-50 50 22.4 50 50-22.4 50-50 50zm0-300c-27.6 0-50-22.4-50-50s22.4-50 50-50 50 22.4 50 50-22.4 50-50 50zM150 750h250v100H150V750zm0-300h250v100H150V450zm0-300h250v100H150V150zm650 100c-27.6 0-50-22.4-50-50s22.4-50 50-50 50 22.4 50 50-22.4 50-50 50zm0 300c-27.6 0-50-22.4-50-50s22.4-50 50-50 50 22.4 50 50-22.4 50-50 50zm0 300c-27.6 0-50-22.4-50-50s22.4-50 50-50 50 22.4 50 50-22.4 50-50 50z',
    viewBox: '0 0 1000 1000',
}

const I1OF3 = {
    d: 'M383.9 305.4v196.4h-46.4V358.9l-30.4 21.5-21.4-30.4 62.5-44.6h35.7zm228.6 14.2L385.7 641.1 350 617.9l225-321.5 37.5 23.2zm96.4 251.8c0 34-28.5 66.1-80.3 66.1-25 0-51.8-5.4-84-26.8l21.5-32.1c26.8 14.3 44.6 17.8 62.5 17.8 21.4 0 33.9-8.9 33.9-26.8s-17.9-26.7-41.1-26.7c-10.7 0-19.6 1.7-23.2 3.5l-19.6-26.8 64.3-48.2h-87.5v-37.5h142.8v37.5l-55.3 41.1c39.2 3.6 66 26.8 66 58.9M491.1 35.7c-251.8 0-455.4 203.6-455.4 455.4 0 251.8 203.6 455.3 455.4 455.3 251.8 0 455.3-203.5 455.3-455.3S742.9 35.7 491.1 35.7m0 946.4C219.6 982.1 0 762.5 0 491.1S219.6 0 491.1 0s491 219.6 491 491.1-219.6 491-491 491',
    viewBox: '0 0 983 983',
}

const I1OF4 = {
    d: 'M491.1 35.7c-251.8 0-455.4 203.6-455.4 455.4 0 251.8 203.6 455.3 455.4 455.3 251.8 0 455.3-203.5 455.3-455.3S742.9 35.7 491.1 35.7m0 946.4C219.6 982.1 0 762.5 0 491.1S219.6 0 491.1 0s491 219.6 491 491.1-219.6 491-491 491M383.9 305.4v196.4h-46.4V358.9l-30.4 21.5-21.4-30.4 62.5-44.6h35.7zm314.3 250v35.7h-25v41h-44.6v-41h-94.7v-42.9l75-112.5h48.2l-78.5 119.7h50v-37.5h44.6v37.5h25zm-85.7-235.8L385.7 641.1 350 617.9l225-321.5 37.5 23.2z',
    viewBox: '0 0 983 983',
}

const I2OF3 = {
    d: 'M287.5 462.5H400V500H239.3v-17.9c0-44.6 30.3-64.2 64.3-80.3 28.5-14.3 51.8-21.4 51.8-41.1 0-12.5-12.5-21.4-32.2-21.4-17.8 0-33.9 7.1-57.1 25l-23.2-30.4c25-23.2 55.3-33.9 82.1-33.9 50 0 76.8 28.6 76.8 58.9 0 41.1-30.4 51.8-71.4 73.2-19.7 10.8-37.5 17.9-42.9 30.4m323.2-142.9L383.9 641.1l-35.7-23.2L575 296.4l35.7 23.2zm96.4 251.8c0 34-28.5 66.1-80.3 66.1-25 0-51.8-5.4-83.9-26.8l21.4-32.1c26.8 14.3 44.6 17.8 62.5 17.8 21.4 0 33.9-8.9 33.9-26.8s-17.8-26.7-41.1-26.7c-10.7 0-19.6 1.7-23.2 3.5l-19.6-26.8 64.3-48.2h-87.5v-37.5h142.8v37.5l-53.5 41.1c37.5 3.6 64.2 26.8 64.2 58.9m-216-535.7c-251.8 0-455.4 203.6-455.4 455.4 0 251.8 203.6 455.3 455.4 455.3 251.8 0 455.3-203.5 455.3-455.3S742.9 35.7 491.1 35.7m0 946.4C219.6 982.1 0 762.5 0 491.1S219.6 0 491.1 0s491 219.6 491 491.1-219.6 491-491 491',
    viewBox: '0 0 983 983',
}

const I2OF4 = {
    d: 'M287.5 462.5H400V500H239.3v-17.9c0-44.6 30.3-64.2 64.3-80.3 28.5-14.3 51.8-21.4 51.8-41.1 0-12.5-12.5-21.4-32.2-21.4-17.8 0-33.9 7.1-57.1 25l-23.2-30.4c25-23.2 55.3-33.9 82.1-33.9 50 0 76.8 28.6 76.8 58.9 0 41.1-30.4 51.8-71.4 73.2-19.7 10.8-37.5 17.9-42.9 30.4m323.2-142.9L383.9 641.1l-35.7-23.2L575 296.4l35.7 23.2zm87.5 235.8v35.7h-26.8v41h-44.6v-41h-94.7v-42.9l75-112.5h48.3l-78.6 119.7h50v-37.5h44.6v37.5h26.8zM491.1 35.7c-251.8 0-455.4 203.6-455.4 455.4 0 251.8 203.6 455.3 455.4 455.3 251.8 0 455.3-203.5 455.3-455.3S742.9 35.7 491.1 35.7m0 946.4C219.6 982.1 0 762.5 0 491.1S219.6 0 491.1 0s491 219.6 491 491.1-219.6 491-491 491',
    viewBox: '0 0 983 983',
}

const I3OF3 = {
    d: 'M408.9 441.1c0 33.9-28.5 66-80.3 66-25 0-51.8-5.3-84-26.7l21.5-32.2c26.8 14.3 44.6 17.9 62.5 17.9 21.4 0 33.9-9 33.9-26.8s-17.9-26.8-41.1-26.8c-10.7 0-19.6 1.8-23.2 3.6l-19.6-26.8 64.3-48.2h-87.5v-37.5h142.8v37.5l-53.6 41c39.3 3.6 64.3 25 64.3 59m201.8-121.5L383.9 641.1l-35.7-23.2 225-321.5 37.5 23.2zm96.4 251.8c0 34-28.5 66.1-80.3 66.1-25 0-51.8-5.4-83.9-26.8l21.4-32.1c26.8 14.3 44.6 17.8 62.5 17.8 21.4 0 33.9-8.9 33.9-26.8s-17.8-26.7-41.1-26.7c-10.7 0-19.6 1.7-23.2 3.5l-19.6-26.8 64.3-48.2h-87.5v-37.5h142.8v37.5l-53.5 41.1c37.5 3.6 64.2 26.8 64.2 58.9m-216-535.7c-251.8 0-455.4 203.6-455.4 455.4 0 251.8 203.6 455.3 455.4 455.3 251.8 0 455.3-203.5 455.3-455.3S742.9 35.7 491.1 35.7m0 946.4C219.6 982.1 0 762.5 0 491.1S219.6 0 491.1 0s491 219.6 491 491.1-219.6 491-491 491',
    viewBox: '0 0 983 983',
}

const I3OF4 = {
    d: 'M408.9 441.1c0 33.9-28.5 66-80.3 66-25 0-51.8-5.3-84-26.7l21.5-32.2c26.8 14.3 44.6 17.9 62.5 17.9 21.4 0 33.9-9 33.9-26.8s-17.9-26.8-41.1-26.8c-10.7 0-19.6 1.8-23.2 3.6l-19.6-26.8 64.3-48.2h-87.5v-37.5h142.8v37.5l-55.3 41c39.2 3.6 66 25 66 59m201.8-121.5L383.9 641.1l-35.7-23.2 225-321.5 37.5 23.2zm85.7 235.8v35.7h-25v41h-44.6v-41h-94.7v-42.9l75-112.5h48.3l-78.6 119.7h50v-37.5h44.6v37.5h25zM491.1 35.7c-251.8 0-455.4 203.6-455.4 455.4 0 251.8 203.6 455.3 455.4 455.3 251.8 0 455.3-203.5 455.3-455.3S742.9 35.7 491.1 35.7m0 946.4C219.6 982.1 0 762.5 0 491.1S219.6 0 491.1 0s491 219.6 491 491.1-219.6 491-491 491',
    viewBox: '0 0 983 983',
}

const I4OF4 = {
    d: 'M414.3 423.2v35.7h-26.8v42.9h-44.6v-42.9h-94.7v-41l75-112.5h48.2l-76.8 117.8h48.3v-37.5h44.6v37.5h26.8zm196.4-103.6L383.9 641.1 350 617.9l225-321.5 35.7 23.2zm87.5 235.8v35.7h-26.8v41h-44.6v-41h-94.7v-42.9l75-112.5h48.3l-76.8 119.7h48.2v-37.5h44.6v37.5h26.8zM491.1 35.7c-251.8 0-455.4 203.6-455.4 455.4 0 251.8 203.6 455.3 455.4 455.3 251.8 0 455.3-203.5 455.3-455.3S742.9 35.7 491.1 35.7m0 946.4C219.6 982.1 0 762.5 0 491.1S219.6 0 491.1 0s491 219.6 491 491.1-219.6 491-491 491',
    viewBox: '0 0 983 983',
}

const LOW = {
    d: 'M100 100v200h800V100H100zM0 99.9C0 44.7 44.9 0 99.5 0h801c54.9 0 99.5 44.3 99.5 99.9v200.2c0 55.2-44.9 99.9-99.5 99.9h-801C44.6 400 0 355.7 0 300.1V99.9zM150 250h250V150H150v100zm500-100c-27.6 0-50 22.4-50 50 0 27.7 22.4 50 50 50s50-22.3 50-50c0-27.6-22.4-50-50-50zm150 0c-27.6 0-50 22.4-50 50 0 27.7 22.4 50 50 50s50-22.3 50-50c0-27.6-22.4-50-50-50z',
    viewBox: '0 0 1000 400',
}

const NAV = {
    d: 'M1050 1000c27.6 0 50-22.4 50-50s-22.4-50-50-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50h1000zm0-450c27.6 0 50-22.4 50-50s-22.4-50-50-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50h1000zm0-450c27.6 0 50-22.4 50-50s-22.4-50-50-50H50C22.4 0 0 22.4 0 50s22.4 50 50 50h1000z',
    viewBox: '0 0 1100 1000',
}

const PLUS = {
    d: 'M652.175 180.775c-130-130-341.5-130-471.4 0-130 129.9-130 341.4 0 471.4 129.9 129.9 341.4 129.9 471.4 0 129.9-130 129.9-341.5 0-471.4m-530.3 530.3c-162.5-162.4-162.5-426.8 0-589.2 162.4-162.5 426.8-162.5 589.2 0 162.5 162.4 162.5 426.8 0 589.2-162.4 162.5-426.8 162.5-589.2 0m526-248.7v-79.7h-185.5v-185.5h-79.6v185.5h-185.6v79.7h185.5l.1 185.5h79.6v-185.5',
    viewBox: '0 0 833 833',
}

const SCROLL_INDICATOR = {
    d: 'M291.725 819V41.7c0-23 18.6-41.7 41.6-41.7s41.7 18.7 41.7 41.7V825l226.5-226.5c16.2-16.3 42.6-16.3 58.9 0 16.3 16.3 16.3 42.7 0 58.9l-294.6 294.7c-16.3 16.2-42.7 16.2-59 0l-294.6-294.7c-16.3-16.2-16.3-42.6 0-58.9 16.3-16.3 42.7-16.3 58.9 0l220.6 220.5z',
    viewBox: '0 0 673 965',
}

const SETTINGS = {
    d: 'M708.4 83.3c22.9 0 41.6 18.7 41.6 41.7 0 23-18.7 41.7-41.6 41.7-23 0-41.7-18.7-41.7-41.7 0-23 18.7-41.7 41.7-41.7m0 166.7c68.9 0 125-56.1 125-125S777.3 0 708.4 0c-54.3 0-100.1 34.9-117.3 83.3H0v83.4h591.1c17.2 48.4 63 83.3 117.3 83.3M291.7 416.7c-23 0-41.7-18.7-41.7-41.7 0-23 18.7-41.7 41.7-41.7 23 0 41.7 18.7 41.7 41.7 0 23-18.7 41.7-41.7 41.7m0-166.7c-54.2 0-100 34.9-117.3 83.3H0v83.4h174.4c17.3 48.4 63 83.3 117.3 83.3s100-34.9 117.3-83.3h424.4v-83.4H409c-17.3-48.4-63.1-83.3-117.3-83.3m416.7 416.7c-23 0-41.7-18.7-41.7-41.7 0-23 18.7-41.7 41.7-41.7 22.9 0 41.6 18.7 41.6 41.7 0 23-18.7 41.7-41.6 41.7m0-166.7c-54.3 0-100.1 34.9-117.3 83.3H0v83.4h591.1c17.2 48.4 63 83.3 117.3 83.3 68.9 0 125-56.1 125-125s-56.1-125-125-125',
    viewBox: '0 0 834 750',
}

const SLIDE_LEFT = {
    d: 'M500.575 50.225l-441.5 441.4c-.2.3 146.9 147.9 441.5 442.9 11.4 11.5 11.4 30.1 0 41.6-11.5 11.5-30.2 11.5-41.6 0l-441.5-441.4c-23.3-23.4-23.3-61.3 0-84.7l441.5-441.4c11.4-11.5 30.1-11.5 41.6 0 11.4 11.5 11.4 30.1 0 41.6z',
    viewBox: '0 0 510 985',
}

const SLIDE_RIGHT = {
    d: 'M8.625 934.525l441.4-441.4c.3-.3-146.9-147.9-441.4-442.9-11.5-11.5-11.5-30.1 0-41.6s30.1-11.5 41.6 0l441.4 441.4c23.4 23.4 23.4 61.3 0 84.7l-441.4 441.4c-11.5 11.5-30.1 11.5-41.6 0s-11.5-30.1 0-41.6z',
    viewBox: '0 0 510 985',
}

const PLAY = {
    d: 'M21.034 12.637l-12.936 8.26A1.364 1.364 0 0 1 6 19.747V4.378a1.364 1.364 0 0 1 2.098-1.15l12.936 8.26a.682.682 0 0 1 0 1.15z',
    viewBox: '0 0 24 24',
}

export {
    ARROW_LEFT,
    ARROW_RIGHT,
    BOOK,
    CLOSE,
    GOLEM_EYE,
    GOLEM_RIGHT_ARROW,
    HIGH,
    I1OF3,
    I1OF4,
    I2OF3,
    I2OF4,
    I3OF3,
    I3OF4,
    I4OF4,
    LOW,
    NAV,
    PLAY,
    PLUS,
    SCROLL_INDICATOR,
    SETTINGS,
    SLIDE_LEFT,
    SLIDE_RIGHT,
}

export default function Glyph({ source: { d, viewBox }, ...props }) {
    return (
        <svg {...props} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
            <path d={d} fill="currentColor" />
        </svg>
    )
}
